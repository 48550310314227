import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Spinner } from "react-bootstrap";
import { sendResetPasswordLink } from '../../Actions/authActions';
import { useAffiliate } from '../../helpers';

const ForgotPassword = ({
    show,
    onHide,
}) => {
    const [isLoad, setLoad] = useState(false);
    const aff = useAffiliate();
    const dispatch = useDispatch();
    const { affiliate } = useParams();
  
    const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
  
    const [fieldsErr, setFieldsErr] = useState({});
    const handleFieldsErr = (value, name) => {
        const obj = { ...fieldsErr };
        obj[name] = value;
        setFieldsErr(obj);
    };
  
    const [fields, setFields] = useState({});
    const handleFields = (value, name) => {
        const obj = { ...fields };
        obj[name] = value;
        setFields(obj);
    };
  
    const isReadyForSend = () => {
        const { email } = fields || {};
        if (!email) {
            handleFieldsErr("Please enter email.", 'email');
        } else if (!isValidEmail(email)) {
            handleFieldsErr("Email isn't valid.", 'email');
        } else {
            return true;
        }
        return false;
    };
  
    useEffect(() => {
        if (fields.email && fieldsErr.email === "Please enter email.") {
            handleFieldsErr('', 'email');
        } else if (isValidEmail(fields.email) && fieldsErr.email === "Email isn't valid.") {
            handleFieldsErr('', 'email');
        }
    }, [fields]);
  
    const handleSend = () => {
      if (isReadyForSend()) {
        setLoad(true);
        dispatch(sendResetPasswordLink({ ...fields, affiliate })).then(success => {
            setLoad(false);
            if (success) {
                onHide();
            }
        });
      }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="dialog"
            className="modal-backdrop-2"
        >
            <Modal.Header className="m-header" closeButton></Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSend(e);
                    }}
                    noValidate=""
                >
                    <div>
                        <h2>Forgot Your Password?</h2>
                        <p>Enter your Email below. We will send you a link to get back into your account to reset your password.</p>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Email Address"
                            className="user"
                            value={fields.email || ''}
                            onChange={e => handleFields(e.target.value, 'email')}
                        />
                        {fieldsErr.email && (
                            <p className="field-error-text">{fieldsErr.email}</p>
                        )}
                    </div>
                    <div className="mt-4">
                        <button
                            type="submit"
                            style={{ backgroundColor: aff('themecolor1') }}
                            className="modal-foot mt-2 ThemeColor1"
                            onClick={handleSend}
                            disabled={isLoad}
                        >
                            {isLoad ? <Spinner animation="border" size="sm" /> : 'SEND'}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
 
export default ForgotPassword;