import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Route
      {...rest}
      render={(props) =>
        !(user && user.id) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
