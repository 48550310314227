
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAffiliate } from '../Actions/authActions';

const useAffiliate = () => {
    const { affiliate } = useParams();
    const dispatch = useDispatch();
    const affiliateData = useSelector(st => st.auth.affiliateData);
    return (type, url) => {
        if (type === 'get') {
            return dispatch(getAffiliate(url || affiliate));
        } else if (type) {
            return (affiliateData || {})[type];
        } else {
            return affiliateData;
        }
    };
};

export default useAffiliate;