export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const OPTIONALCOVERAGE_LOADING = "OPTIONALCOVERAGE_LOADING";
export const OPTIONALCOVERAGE_SUCCESS = "OPTIONALCOVERAGE_SUCCESS";
export const OPTIONALCOVERAGE_ERROR = "OPTIONALCOVERAGE_ERROR";

export const PLAN_LOADING = "PLAN_LOADING";
export const PLAN_SUCCESS = "PLAN_SUCCESS";
export const PLAN_ERROR = "PLAN_ERROR";

export const FILE_UPLOAD_LOADING = "FILE_UPLOAD_LOADING";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";

export const TASK_SAVE_LOADING = "TASK_SAVE_LOADING";
export const TASK_SAVE_SUCCESS = "TASK_SAVE_SUCCESS";
export const TASK_SAVE_ERROR = "TASK_SAVE_ERROR";

export const TASK_DATA_LOADING = "TASK_DATA_LOADING";
export const TASK_DATA_SUCCESS = "TASK_DATA_SUCCESS";
export const TASK_DATA_ERROR = "TASK_DATA_ERROR";

export const QUOTE_LOADING = "QUOTE_LOADING";
export const QUOTE_SUCCESS = "QUOTE_SUCCESS";
export const QUOTE_ERROR = "QUOTE_ERROR";

export const QUOTE_DATA_LOADING = "QUOTE_DATA_LOADING";
export const QUOTE_DATA_SUCCESS = "QUOTE_DATA_SUCCESS";
export const QUOTE_DATA_ERROR = "QUOTE_DATA_ERROR";

export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const SETTING_LOADING = "SETTING_LOADING";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_ERROR = "SETTING_ERROR";

export const PROPERTY_TYPE_LOADING = "PROPERTY_TYPE_LOADING";
export const PROPERTY_TYPE_SUCCESS = "PROPERTY_TYPE_SUCCESS";
export const PROPERTY_TYPE_ERROR = "PROPERTY_TYPE_ERROR";

export const SAVE_HOME_WARRANTY_LOADING = "SAVE_HOME_WARRANTY_LOADING";
export const SAVE_HOME_WARRANTY_SUCCESS = "SAVE_HOME_WARRANTY_SUCCESS";
export const SAVE_HOME_WARRANTY_ERROR = "SAVE_HOME_WARRANTY_ERROR";

export const FETCH_PROMO_CODE_LOADING = "FETCH_PROMO_CODE_LOADING";
export const FETCH_PROMO_CODE_SUCCESS = "FETCH_PROMO_CODE_SUCCESS";
export const FETCH_PROMO_CODE_ERROR = "FETCH_PROMO_CODE_ERROR";

export const FETCH_AFFILIATE_LOADING = "FETCH_AFFILIATE_LOADING";
export const FETCH_AFFILIATE_SUCCESS = "FETCH_AFFILIATE_SUCCESS";
export const FETCH_AFFILIATE_ERROR = "FETCH_AFFILIATE_ERROR";

export const SET_FORM_DATA = "SET_FORM_DATA";

export const SET_FORM_STEP = "SET_FORM_STEP";
