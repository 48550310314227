import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import routes from "./routes";
import { Switch, Route, useLocation } from "react-router-dom";
import { withProtectedLayout } from "./withProtectedLayout";
import ProtectedRoute from "./Components/ProtectedRoute";
import AuthRoute from "./Components/AuthRoute";
import { getSetting, getPropertyType, getPlan } from './Actions/authActions';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser, useAffiliate, useRedirect } from './helpers';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useUser();
  const aff = useAffiliate();
  const redirectTo = useRedirect();

  const loadScript = (src) => {
    let tag = document.createElement("script");
    tag.src = src;
    document.body.appendChild(tag);
  };

  const path = (i) => {
    const { pathname } = location;
    return pathname.split('/')[i];
  };

  const [isAffLoaded, setAffLoaded] = useState(false);
  const loadData = () => {
    setAffLoaded(true);
    if (localStorage.getItem("token")) {
      user('get');
    }
    dispatch(getSetting());
    dispatch(getPropertyType());
    dispatch(getPlan());
    if (process.env.REACT_APP_AUTHORIZE_MODE === "production") {
      loadScript("https://js.authorize.net/v1/Accept.js");
    } else {
      loadScript("https://jstest.authorize.net/v1/Accept.js");
    }
  };

  useEffect(() => {
    if (path(1) && path(1) !== 'none') {
      aff('get', path(1)).then(() => {
        loadData();
      });
    } else {
      loadData();
    }
  }, []);

  useEffect(() => {
    redirectTo('free-quote', path(1), path(2), path(3));
  }, [user()]);

  if(!isAffLoaded) {
    return (
      <div className="aff-loader-container">
        <BeatLoader />
      </div>
    );
  }

  return (
    <Switch>
      {(routes || []).map((route) => {
        const {
          path,
          exact,
          component,
          isProtected,
          isAuthPage,
          isProtectedLayout,
        } = route || {};
        const comp = isProtectedLayout
          ? (props) => withProtectedLayout(props, component)
          : component;
        if (isProtected) {
          return (
            <ProtectedRoute
              key={path}
              exact={exact}
              path={path}
              component={comp}
            />
          );
        } else if (isAuthPage) {
          return (
            <AuthRoute key={path} exact={exact} path={path} component={comp} />
          );
        } else {
          return (
            <Route key={path} exact={exact} path={path} component={comp} />
          );
        }
      })}
    </Switch>
  );
};

export default App;
