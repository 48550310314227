import React, { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useRedirect } from '../../helpers';
import './style.css';
import LazyLoadImages from "../LazyLoad/Image";

const Sidebar = props => {
  const redirectTo = useRedirect();
  const CustomToggle = forwardRef(({ children, onClick }, ref) => {
    return (
      <li
        className="navfirst clickable"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </li>
    );
  });

  const CustomItem = forwardRef(
    ({ children, onClick, className, href }, ref) => {
      return (
        <a
          ref={ref}
          onClick={(e) => {
            onClick(e);
          }}
          href={href}
          target="_blank"
          rel="noreferrer"
          className={className}
        >
          {children}
        </a>
      );
    }
  );

    return (
      <span className="side-bar-menu">
        <div className={props.style}>
          <span className='close-icon mb-3' onClick={() => props.handleClick('close')}>
            <div>
              <span>×</span>
            </div>
          </span>
        <div>
          <ul className="nav">
            <Dropdown className='side-menu-drop-down'>
              <Dropdown.Toggle as={CustomToggle}>Services</Dropdown.Toggle>
              <Dropdown.Menu className='ml-1'>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/home-inspection"
                >
                  Home Inspection
                </Dropdown.Item>
                <Dropdown.Item>
                  Home Warranty
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/environmental"
                >
                  Environmental Services
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="dropdown side-menu-drop-down">
              <li
                className="navfirst clickable"
                onClick={(e) => {
                  redirectTo('locations');
                  props.handleClick('close');
                }}
              >
                Locations
              </li>
            </div>
            {/* <Dropdown className='side-menu-drop-down'>
              <Dropdown.Toggle as={CustomToggle}>Locations</Dropdown.Toggle>
              <Dropdown.Menu className='ml-1'>
                <Dropdown.Item className="location-sub-menu-container">
                  Florida
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/panama-city-fl",
                          "_blank"
                        )
                      }
                    >
                      Panama
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/pensacola-fl",
                          "_blank"
                        )
                      }
                    >
                      Pensacola
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/southwest-fl",
                          "_blank"
                        )
                      }
                    >
                      Southwest
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/tallahassee-fl",
                          "_blank"
                        )
                      }
                    >
                      Tallahassee
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/tampa-fl",
                          "_blank"
                        )
                      }
                    >
                      Tampa
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Idaho
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/boise-id",
                          "_blank"
                        )
                      }
                    >
                      Boise
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Indiana
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/indianapolis-in",
                          "_blank"
                        )
                      }
                    >
                      Indianapolis
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/south-bend-in",
                          "_blank"
                        )
                      }
                    >
                      South Bend
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  New Jersey
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/north-nj",
                          "_blank"
                        )
                      }
                    >
                      North
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/central-nj",
                          "_blank"
                        )
                      }
                    >
                      Central
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Pennsylvania
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/philadelphia-pa",
                          "_blank"
                        )
                      }
                    >
                      Philadelphia
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Texas
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/austin-tx",
                          "_blank"
                        )
                      }
                    >
                      Austin
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/dallas-tx",
                          "_blank"
                        )
                      }
                    >
                      Dallas
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/fort-worth-tx",
                          "_blank"
                        )
                      }
                    >
                      Fort Worth
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/houston-tx",
                          "_blank"
                        )
                      }
                    >
                      Houston
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/san-antonio-tx",
                          "_blank"
                        )
                      }
                    >
                      San Antonio
                    </li>
                  </ul>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown className='side-menu-drop-down'>
              <Dropdown.Toggle as={CustomToggle}>About Us</Dropdown.Toggle>
              <Dropdown.Menu className='ml-1'>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/about"
                >
                  Our Company
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/reviews"
                >
                  Reviews
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/faqs"
                >
                  FAQ's
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/team"
                >
                  Our Team
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
        <div className='side-bar-menu-btn'>
          <div>
            <button className="header-right-btns header-right-btn-1">
              <a href='https://share.hsforms.com/13hc70MgVQsyUkPmeu048xQ2wbdi' target="_blank" rel="noopener noreferrer">SERVICE REQUEST</a>
            </button>
            <a href="https://folio.homeonewarranty.com/customerportal/signin/unauthenticated" target="_blank" rel="noopener noreferrer">
              <button
                className="header-right-btns header-right-btn-2 p-0 mt-2 side-bar-login-btn"
              >
                <img src="/image/log-in.svg" alt="" />
                <span className="ml-2">LOG IN</span>
              </button>
            </a>
          </div>
        </div>
        <div className='side-bar-logo'>
          <div>
          <LazyLoadImages className="side-bar-img-contain" src="/image/header-logo.png" />
          </div>
        </div>
      </div>
    </span>
    );
};

export default Sidebar;
