import React from "react";
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { fetchQuoteNumber } from "../Actions/authActions";
import { barlowRegular, barlowBold } from "../fontBase64";
import useUser from './useUser';
import useAffiliate from './useAffiliate';
import useRedirect from './useRedirect';

export { useUser, useAffiliate, useRedirect };

const buttonLoaderCss = css`
  width: 100%;
  height: 17.28px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const capitalizeFirstLetter = (word) => {
  return (word.length > 0)
    ? word[0].toUpperCase() + word.substring(1)
    : word
}

export const withButtonLoader = (text, isLoad) => {
  if (isLoad) {
    return <BeatLoader css={buttonLoaderCss} color="#fff" />;
  }
  return text;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const generateQuotePDF = async (e, editableData, aff) => {
  const { freeQuote, about, selectPlan } = editableData || {};
  const { addr, addr2, city, state, zip } = freeQuote || {};
  const { first, last, phone, email } = about || {};
  const { plan, coverage } = selectPlan || {};
  let doc = new jsPDF('', '', '', true);
  doc.page = 1;
  let width = doc.internal.pageSize.getWidth();
  let height = doc.internal.pageSize.getHeight();
  const posX = (num) => width - num;
  const posY = (num) => height - num;
  let line = 10;
  const rHeight = 5;

  const addPageNumber = () => {
    let page = doc.internal.getNumberOfPages();
    let pageNumber = `Page ${page}`;
    const prevSize = doc.getFontSize();
    doc.setFontSize(10);
    doc.text(pageNumber, posX(100), posY(10), "center");
    doc.setFontSize(prevSize);
    doc.page = page;
  };

  const setLine = (num, is) => {
    if (is) {
      const { pageNumber } = num || {};
      if (doc.page !== pageNumber) {
        addPageNumber();
      }
    } else {
      line = line + num;
      if (line >= height - 30) {
        line = 10;
        doc.addPage();
        addPageNumber();
      }
    }
  };

  const mixedText = (inputValue, startX, startY, fontSize) => {
    const arrayOfNormalAndBoldText = inputValue.split('**');
    arrayOfNormalAndBoldText.map((text, i) => {
      doc.setFont("Barlow", "bold");
      if (i % 2 === 0) {
        doc.setFont("Barlow", "normal");
      }
      doc.text(text, startX, startY);
      startX = startX + doc.getStringUnitWidth(text) * fontSize * 0.4;
    });
  };

  doc.addFileToVFS("Barlow-Regular.ttf", barlowRegular);
  doc.addFileToVFS("Barlow-Bold.ttf", barlowBold);
  doc.addFont("Barlow-Regular.ttf", "Barlow", "normal");
  doc.addFont("Barlow-Bold.ttf", "Barlow", "bold");
  doc.setFont("Barlow", "normal");
  doc.setFontSize(10);

  addPageNumber();

  const quoteNumber = await fetchQuoteNumber();
  doc.text(`Quote Number: ${quoteNumber || "-"}`, posX(10), line, "right");
  setLine(rHeight);
  doc.text(
    `Effective Until ${moment().add(30, "days").format("MM/DD/YYYY")}`,
    posX(10),
    line,
    "right"
  );

  let logo = new Image();
  logo.src = aff && aff('logo') || "/image/header-logo.png";
  const logoHeight = (70 * logo.height) / logo.width;
  doc.addImage(logo, "png", posX(135), line, 70, logoHeight);

  setLine(rHeight + logoHeight);
  doc.setFontSize(14);
  let coveredAddr = [
    [
      "ADDRESS:",
      `${addr} ${addr2 || ""}`,
    ],
    ["CITY:", city],
    ["STATE:", state],
    ["ZIP:", zip],
  ];
  let buyerInfo = [
    ["FIRST NAME:", first],
    ["LAST NAME:", last],
    ["EMAIL:", email],
    ["PHONE:", phone],
  ];

  setLine(rHeight + 2);
  doc.setFont("Barlow", "bold");
  doc.text(`Home Warranty - ${(plan && plan.name) || "No"} Plan`, 15, line);
  doc.setFont("Barlow", "normal");
  doc.text(`Total: $${(plan && plan.amount) || 0}`, posX(15), line, "right");
  doc.setDrawColor(230, 230, 230);
  setLine(rHeight);
  doc.line(15, line, posX(15), line);

  const covArr = Object.keys(coverage || {}).filter(
    (filt) => coverage[filt]["qty"]
  );
  const totalCov = (() => {
    let total = Number((plan && plan.amount) || 0);
    covArr.map((key) => {
      const { amount, qty } = coverage[key] || {};
      total = total + Number(amount || 0) * Number(qty || 0);
      return key;
    });
    return total;
  })();

  const _covArr =
    covArr && covArr.length
      ? covArr.map((key) => ({
          addon: key,
          qty: coverage[key]["qty"] || 0,
          cost: `$${
            (coverage[key]["amount"] &&
            Number(coverage[key]["amount"]) *
            Number(coverage[key]["qty"])) ||
            0
          }`,
        }))
      : [
          {
            addon: "N/A",
            qty: "",
            cost: "",
          },
        ];

  setLine(rHeight);
  doc.autoTable(
    [
      {
        title: "Add ons:",
        dataKey: "addon",
      },
      {
        title: "# of Items",
        dataKey: "qty",
      },
      {
        title: "Cost",
        dataKey: "cost",
      },
    ],
    [
      ..._covArr,
      {
        addon: "TOTAL CONTRACT FEE:",
        qty: "",
        cost: `Total: $${Number(totalCov)}`,
      },
      {
        addon: "",
        qty: "",
        //only this QUOTE pdf uses this code for effective date. Invoice PDFs use a different formula for effective date
        cost: `Effective Date: ${moment().format("MM/DD/YYYY")}`, 
      },
    ],
    {
      startX: 10,
      startY: line,
      theme: "plain",
      willDrawCell: (data) => {
        const { doc } = data || {};
        if (data.row.index !== ((_covArr && _covArr.length) || 0) + 1) {
          doc.setDrawColor(230, 230, 230);
          doc.setLineWidth(0.01);
          doc.line(
            data.cell.x,
            data.cell.y + data.row.height,
            data.cell.x + data.cell.width,
            data.cell.y + data.row.height
          );
        }
      },
      createdCell: (data) => {
        const { cell, column } = data || {};
        const { raw } = cell || {};
        const { dataKey } = column || {};
        cell.styles.fontSize = 14;
        if (column.index === 1) {
          cell.styles.minCellWidth = 27;
        }
        if (["TOTAL CONTRACT FEE:"].includes(raw)) {
          cell.styles.fontStyle = "bold";
        }
        if (["cost"].includes(dataKey)) {
          cell.styles.halign = "right";
        }
        if (["qty"].includes(dataKey)) {
          cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => setLine(data, true),
    }
  );

  setLine(doc.lastAutoTable.finalY - rHeight * 4);
  doc.setFont("Barlow", "bold");
  doc.text("COVERED ADDRESS", 10, line);
  setLine(rHeight + 4);
  doc.setFont("Barlow", "normal");

  coveredAddr.map((data) => {
    mixedText(`${data[0]} **${data[1]}**`, 10, line, 14);
    setLine(rHeight + 2);
  });

  setLine(rHeight + 4);
  doc.setFont("Barlow", "bold");
  doc.text("BUYER INFORMATION", 10, line);
  setLine(rHeight + 4);
  doc.setFont("Barlow", "normal");
  buyerInfo.map((data) => {
    mixedText(`${data[0]} **${data[1]}**`, 10, line, 14);
    setLine(rHeight + 2);
  });

  setLine(rHeight + 2);
  doc.setFont("Barlow", "bold");
  doc.text("HouseCheck Home Warranty", 10, line);
  setLine(rHeight + 2);
  doc.setFont("Barlow", "normal");
  doc.text("950 West Bannock Street", 10, line);
  setLine(rHeight + 2);
  doc.text("Suite 1100", 10, line);
  setLine(rHeight + 2);
  doc.text("Boise, ID 83702", 10, line);
  setLine(rHeight + 2);
  doc.text("support@housecheckwarranty.com", 10, line);
  setLine(rHeight + 2);
  doc.text("866-HCWRNTY(429-7689)", 10, line);

  setLine(rHeight * 3);
  doc.setFontSize(10);
  doc.setTextColor(aff && aff('themecolor1') || "#eb4335");
  doc.text("ACKNOWLEDGEMENT", 10, line);
  setLine(rHeight);
  doc.setTextColor("#000000");
  doc.text(
    "I understand the terms of this Residential Service Contract ('Contract') and the coverage I will receive upon payment of the Contract fee. The Coverage is not all inclusive; and contains specific exclusions and limitations. I have read and accept the terms of cancellation and arbitration stated herein. Furthermore, I understand this Contract is optional, and any sale is not conditioned on the purchase of this Contract",
    10,
    line,
    { maxWidth: width - 20 }
  );

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(doc.output("blob"), "HouseCheckWarrantyQuote.pdf");
  } else {
    // doc.autoPrint();
    window.open(
      URL.createObjectURL(doc.output("blob")),
      "_blank",
      "height=650,width=500,scrollbars=yes,location=yes"
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(doc.output("bloburl"));
    }, 100);
  }

  // Save PDF
  // doc.save('HouseCheckWarrantyQuote.pdf');
};

export const generateInvoicePDF = (e, user, aff) => {
  const {
    invoiceNumber,
    propertyLocationAddress1,
    propertyLocationAddress2,
    propertyLocationCity,
    propertyLocationState,
    propertyLocationZip,
    firstName,
    lastName,
    phone,
    email,
    planName,
    planAmount,
    coverage,
    totalAmount,
    paidBy,
    homeType,
    escrowInfo,
    companyInfo,
    transactionInfo,
  } = user || {};
  const {
    representing,
    compName,
    address: escrowAddr,
    city: escrowCity,
    state: escrowState,
    zip: escrowZip,
    agentName,
    email: escrowEmail,
  } = escrowInfo || {};
  const {
    titleCompanyName,
    address: titleCompAddr,
    city: titleCompCity,
    state: titleCompState,
    zip: titleCompZip,
    agentName: closingAgentName,
    agentEmail,
    agentPhone,
    fileNumber,
    closingDate,
  } = companyInfo || {};
  const {
    cardType,
    defaultCard,
    transactionId,
    authCode,
  } = transactionInfo || {};
  let doc = new jsPDF('','','',true);
  doc.page = 1;
  let width = doc.internal.pageSize.getWidth();
  let height = doc.internal.pageSize.getHeight();
  const posX = (num) => width - num;
  const posY = (num) => height - num;
  let line = 10;
  const rHeight = 5;

  const addPageNumber = () => {
    let page = doc.internal.getNumberOfPages();
    let pageNumber = `Page ${page}`;
    const prevSize = doc.getFontSize();
    doc.setFontSize(10);
    doc.text(pageNumber, posX(100), posY(10), "center");
    doc.setFontSize(prevSize);
    doc.page = page;
  };

  const setLine = (num, is) => {
    if (is) {
      const { pageNumber } = num || {};
      if (doc.page !== pageNumber) {
        addPageNumber();
      }
    } else {
      line = line + num;
      if (line >= height - 30) {
        line = 10;
        doc.addPage();
        addPageNumber();
      }
    }
  };

  const mixedText = (inputValue, startX, startY, fontSize) => {
    const arrayOfNormalAndBoldText = inputValue.split("**");
    arrayOfNormalAndBoldText.map((text, i) => {
      doc.setFont("Barlow", "bold");
      if (i % 2 === 0) {
        doc.setFont("Barlow", "normal");
      }
      doc.text(text, startX, startY);
      startX = startX + doc.getStringUnitWidth(text) * fontSize * 0.4;
    });
  };

  doc.addFileToVFS("Barlow-Regular.ttf", barlowRegular);
  doc.addFileToVFS("Barlow-Bold.ttf", barlowBold);
  doc.addFont("Barlow-Regular.ttf", "Barlow", "normal");
  doc.addFont("Barlow-Bold.ttf", "Barlow", "bold");
  doc.setFont("Barlow", "normal");
  doc.setFontSize(10);

  addPageNumber();

  doc.text(`${paidBy === 'cc' ? 'Receipt' : 'Invoice'} Number: ${invoiceNumber}`, posX(15), line, "right");

  let logo = new Image();
  logo.src = aff && aff('logo') || "/image/header-logo.png";
  const logoHeight = (70 * logo.height) / logo.width;
  doc.addImage(logo, "png", posX(135), line, 70, logoHeight);

  setLine(rHeight + logoHeight);
  doc.setFontSize(14);
  let coveredAddr = [
    [
      "ADDRESS:",
      `${propertyLocationAddress1} ${propertyLocationAddress2 || ""}`,
    ],
    ["CITY:", propertyLocationCity],
    ["STATE:", propertyLocationState],
    ["ZIP:", propertyLocationZip],
  ];
  let buyerInfo = [];
  let companyInfoData = [];
  let titleCompInfo = [];
  let warrantyHolderInfo = [];

  if (paidBy === "escrow") {
    coveredAddr = [
      ...coveredAddr,
      ["PROPERTY TYPE:", homeType],
      ["SQUARE FEET OF PROPERTY:", "-"],
    ];
    companyInfoData = [
      ["REAL ESTATE OFFICE NAME:", compName],
      ["REAL ESTATE OFFICE ADDRESS:", escrowAddr],
      ["REAL ESTATE OFFICE CITY:", escrowCity],
      ["REAL ESTATE OFFICE STATE:", escrowState],
      ["REAL ESTATE OFFICE ZIP:", escrowZip],
      ["AGENT NAME:", agentName],
      ["AGENT EMAIL:", escrowEmail],
      ["AGENT PHONE:", "-"],
      ["AGENT REPRESENTS:", representing],
    ];
    titleCompInfo = [
      ["TITLE COMPANY NAME:", titleCompanyName],
      ["TITLE COMPANY EMAIL:", agentEmail],
      ["TITLE COMPANY ADDRESS:", titleCompAddr],
      ["TITLE COMPANY CITY:", titleCompCity],
      ["TITLE COMPANY STATE:", titleCompState],
      ["TITLE COMPANY ZIP:", titleCompZip],
      ["CLOSING DATE:", closingDate],
      ["CLOSING AGENT NAME:", closingAgentName],
      ["CLOSING FILE NUMBER:", fileNumber],
      ["CLOSING AGENT PHONE:", agentPhone],
    ];
    warrantyHolderInfo = [
      ["FIRST NAME:", firstName],
      ["LAST NAME:", lastName],
      [
        "ADDRESS:",
        `${propertyLocationAddress1} ${propertyLocationAddress2 || ""}`,
      ],
      ["CITY:", propertyLocationCity],
      ["STATE:", propertyLocationState],
      ["ZIP:", propertyLocationZip],
      ["EMAIL", email],
      ["PHONE:", phone],
      ["PAYMENT METHOD:", 'Escrow'],
    ];
  } else {
    buyerInfo = [
      ["FIRST NAME:", firstName],
      ["LAST NAME:", lastName],
      ["EMAIL:", email],
      ["PHONE:", phone],
      ["PAYMENT METHOD:", cardType && defaultCard ? `${capitalizeFirstLetter(cardType)} ${defaultCard}` : '-'],
      ["TRANSACTION ID:", transactionId || '-'],
      ["AUTHORIZATION CODE:", authCode || '-'],
    ];
  }

  doc.setFont("Barlow", "bold");
  doc.text(
    `This warranty is part of a transaction in escrow: ${paidBy === "escrow" ? "yes" : "no"}`,
    15,
    line
  );
  setLine(rHeight + 2);
  doc.text(`Home Warranty - ${planName || "No"} Plan`, 15, line);
  doc.setFont("Barlow", "normal");
  doc.text(`Total: $${planAmount || 0}`, posX(15), line, "right");
  doc.setDrawColor(230, 230, 230);
  setLine(rHeight);
  doc.line(15, line, posX(15), line);

  const _covArr =
    coverage && coverage.length
      ? coverage.map((cov) => {
          const { name, qty, amount } = cov || {};
          return {
            addon: name,
            qty: qty || 0,
            cost: `$${(amount && Number(amount) * Number(qty)) || 0}`,
          };
        })
      : [
          {
            addon: "N/A",
            qty: "",
            cost: "",
          },
        ];

  setLine(rHeight);
  let noteRow = [];
  if (paidBy === 'escrow') {
    noteRow.push({
      addon: "",
      qty: "",
      cost: "Policy is not active until payment is received",
    });
  }
  doc.autoTable(
    [
      {
        title: "Add ons:",
        dataKey: "addon",
      },
      {
        title: "# of Items",
        dataKey: "qty",
      },
      {
        title: "Cost",
        dataKey: "cost",
      },
    ],
    [
      ..._covArr,
      {
        addon: "TOTAL CONTRACT FEE:",
        qty: "",
        cost: `Total: $${Number(totalAmount)}`,
      },
      {
        addon: "",
        qty: "",
        cost: `Effective Date: ${paidBy === 'cc' ? moment().format("MM/DD/YYYY") : moment(new Date(closingDate)).format("MM/DD/YYYY")}`,
      },
      ...noteRow,
    ],
    {
      startX: 10,
      startY: line,
      theme: "plain",
      willDrawCell: (data) => {
        const { doc } = data || {};
        if (data.row.index !== ((_covArr && _covArr.length) || 0) + 1) {
          doc.setDrawColor(230, 230, 230);
          doc.setLineWidth(0.01);
          doc.line(
            data.cell.x,
            data.cell.y + data.row.height,
            data.cell.x + data.cell.width,
            data.cell.y + data.row.height
          );
        }
      },
      createdCell: (data) => {
        const { cell, column } = data || {};
        const { raw } = cell || {};
        const { dataKey } = column || {};
        cell.styles.fontSize = 14;
        if (column.index === 1) {
          cell.styles.minCellWidth = 27;
        }
        if (["TOTAL CONTRACT FEE:"].includes(raw)) {
          cell.styles.fontStyle = "bold";
        }
        if (["Policy is not active until payment is received"].includes(raw)) {
          cell.styles.fontSize = 10;
          cell.styles.fontStyle = "italic";
        }
        if (["cost"].includes(dataKey)) {
          cell.styles.halign = "right";
        }
        if (["qty"].includes(dataKey)) {
          cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => setLine(data, true),
    }
  );

  setLine(doc.lastAutoTable.finalY - rHeight * 4);
  doc.setFont("Barlow", "bold");
  doc.text("COVERED ADDRESS", 15, line);
  doc.setFont("Barlow", "normal");
  setLine(rHeight + 4);
  coveredAddr.map((data) => {
    mixedText(`${data[0]} **${data[1]}**`, 15, line, 14);
    setLine(rHeight + 2);
  });

  if (paidBy === "escrow") {
    setLine(rHeight + 4);
    doc.setFont("Barlow", "bold");
    doc.text("REAL ESTATE AGENT/COMPANY INFO", 15, line);
    doc.setFont("Barlow", "normal");
    setLine(rHeight + 4);
    companyInfoData.map((data) => {
      mixedText(`${data[0]} **${data[1]}**`, 15, line, 14);
      setLine(rHeight + 2);
    });

    setLine(rHeight + 4);
    doc.setFont("Barlow", "bold");
    doc.text("TITLE COMPANY INFO", 15, line);
    doc.setFont("Barlow", "normal");
    setLine(rHeight + 4);
    titleCompInfo.map((data) => {
      mixedText(`${data[0]} **${data[1]}**`, 15, line, 14);
      setLine(rHeight + 2);
    });

    setLine(rHeight + 4);
    doc.setFont("Barlow", "bold");
    doc.text("WARRANTY HOLDER INFO", 15, line);
    doc.setFont("Barlow", "normal");
    setLine(rHeight + 4);
    warrantyHolderInfo.map((data) => {
      mixedText(`${data[0]} **${data[1]}**`, 15, line, 14);
      setLine(rHeight + 2);
    });
  } else {
    setLine(rHeight + 4);
    doc.setFont("Barlow", "bold");
    doc.text("BUYER INFORMATION", 15, line);
    doc.setFont("Barlow", "normal");
    setLine(rHeight + 4);
    buyerInfo.map((data) => {
      mixedText(`${data[0]} **${data[1]}**`, 15, line, 14);
      setLine(rHeight + 2);
    });
  }

  setLine(rHeight + 2);
  doc.setFont("Barlow", "bold");
  doc.text("HouseCheck Home Warranty", 15, line);
  setLine(rHeight + 2);
  doc.setFont("Barlow", "normal");
  doc.text("950 West Bannock Street", 15, line);
  setLine(rHeight + 2);
  doc.text("Suite 1100", 15, line);
  setLine(rHeight + 2);
  doc.text("Boise, ID 83702", 15, line);
  setLine(rHeight + 2);
  doc.text("support@housecheckwarranty.com", 15, line);
  setLine(rHeight + 2);
  doc.text("866-HCWRNTY(429-7689)", 15, line);

  setLine(rHeight * 3);
  doc.setFontSize(10);
  doc.setTextColor(aff && aff('themecolor1') || "#eb4335");
  doc.text("ACKNOWLEDGEMENT", 15, line);
  setLine(rHeight);
  doc.setTextColor("#000000");
  doc.text(
    "I understand the terms of this Residential Service Contract ('Contract') and the coverage I will receive upon payment of the Contract fee. The Coverage is not all inclusive; and contains specific exclusions and limitations. I have read and accept the terms of cancellation and arbitration stated herein. Furthermore, I understand this Contract is optional, and any sale is not conditioned on the purchase of this Contract",
    15,
    line,
    { maxWidth: width - 30 }
  );

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(doc.output("blob"), "HouseCheckWarrantyQuote.pdf");
  } else {
    // doc.autoPrint();
    window.open(
      URL.createObjectURL(doc.output("blob")),
      "_blank",
      "height=650,width=500,scrollbars=yes,location=yes"
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(doc.output("bloburl"));
    }, 100);
  }

  // Save PDF
  // doc.save('HouseCheckWarrantyQuote.pdf');
};

export const scrollToElement = ({
  id, 
  dist, 
  type,
  behavior,
  timeout,
}) => {
  const elem = document.getElementById(id);
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (elem) {
    const winScrollTo = () => {
      const { top } = elem.getBoundingClientRect() || {};
      const elemTop = top + scrollTop;
      window.scrollTo({
        top: type === "bottom" ? elemTop + (dist || 0) : elemTop - (dist || 0),
        behavior,
      });
    };
    if (timeout > 0) {
      setTimeout(() => winScrollTo(), timeout);
    } else {
      winScrollTo();
    }
  }
};

export const USER_TYPE = {
  partnerUsers: "partnerUsers",
};