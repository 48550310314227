import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import './style.css';

import React from "react";

const LazyLoadImages = props => {
  return (
    <LazyLoadImage
      {...props}
      alt={props.alt || 'images'}
    />
  );
};

export default trackWindowScroll(LazyLoadImages);
