import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import registerServiceWorker from './registerServiceWorker';
import store from "./Redux/store";

const history = createBrowserHistory();

ReactDOM.render(
  <>
    <Provider store={store}>
      <Router history={history}>
        <App />
        <ToastContainer />
      </Router>
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
registerServiceWorker();
