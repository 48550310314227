import { toast } from "react-toastify";
import { executePost, executeGet } from "../Api";
import { setAuthHeader } from '../api/axiosApi';
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  OPTIONALCOVERAGE_LOADING,
  OPTIONALCOVERAGE_SUCCESS,
  OPTIONALCOVERAGE_ERROR,
  PLAN_LOADING,
  PLAN_SUCCESS,
  PLAN_ERROR,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SETTING_LOADING,
  SETTING_SUCCESS,
  SETTING_ERROR,
  PROPERTY_TYPE_LOADING,
  PROPERTY_TYPE_SUCCESS,
  PROPERTY_TYPE_ERROR,
  SAVE_HOME_WARRANTY_LOADING,
  SAVE_HOME_WARRANTY_SUCCESS,
  SAVE_HOME_WARRANTY_ERROR,
  FETCH_PROMO_CODE_LOADING,
  FETCH_PROMO_CODE_SUCCESS,
  FETCH_PROMO_CODE_ERROR,
  FETCH_AFFILIATE_LOADING,
  FETCH_AFFILIATE_SUCCESS,
  FETCH_AFFILIATE_ERROR,
} from "../constants";

export const setUserData = () => {
  return (dispatch, state) => {
    dispatch({ type: LOGIN_LOADING });
    return executeGet("users/current", {})
      .then((res) => {
        const { data } = res || {};
        dispatch({ type: LOGIN_SUCCESS, payload: data });
        return data;
      })
      .catch((e) => {
        dispatch({ type: LOGIN_ERROR });
        return false;
      });
  };
};

export const loginAct = (data) => {
  return (dispatch, state) => {
    dispatch({ type: LOGIN_LOADING });
    return executePost("users/authenticate", data)
      .then((res) => {
        const { data } = res || {};
        const { token } = data || {};
        if (token) {
          setAuthHeader(token);
          localStorage.setItem("token", token);
          dispatch(setUserData());
        }
        dispatch({ type: LOGIN_SUCCESS, 
          payload: {
            data, status: { success: !!token },
          }
        });
        return { success: !!token, message: '' };
      })
      .catch((err) => {
        const { response } = err || {};
        const { data } = response || {};
        const { message } = data || {};
        dispatch({ type: LOGIN_ERROR });
        return { success: false, message: message || '' };
      });
  };
};

export const registerAct = (data) => {
  return (dispatch, state) => {
    dispatch({ type: REGISTER_LOADING });
    executePost("users/register", data)
      .then((res) => {
        const { data } = res || {};
        return dispatch({ type: REGISTER_SUCCESS, payload: data });
      })
      .catch(() => {
        return dispatch({ type: REGISTER_ERROR });
      });
  };
};

export const sendResetPasswordLink = (data) => {
  return (dispatch, state) => {
    return executePost("users/sendForgotEmail", data)
      .then((res) => {
        const { data } = res || {};
        const { message } = data || {};
        toast.success(message || 'Password reset link has been sent to your email');
        return true;
      })
      .catch((err) => {
        const { response } = err || {};
        const { data } = response || {};
        const { message } = data || {};
        toast.error(message || 'Something went wrong');
        return false;
      });
  };
};

export const resetPasswordAct = (data) => {
  return (dispatch, state) => {
    return executePost("users/updatePassword", data)
      .then((res) => {
        const { data } = res || {};
        const { message } = data || {};
        toast.success(message || 'Success! Your Password has been changed!');
        return true;
      })
      .catch((err) => {
        const { response } = err || {};
        const { data } = response || {};
        const { message } = data || {};
        toast.error(message || 'Something went wrong');
        return false;
      });
  };
};

export const fetchQuoteNumber = () => {
  return executeGet("homeWarranty/quoteNumber", {})
    .then((res) => {
      const { data } = res || {};
      const { quoteNumber } = data || {};
      return quoteNumber;
    })
    .catch((e) => {
      return false;
    });
};

export const getOptionalCoverage = () => {
  return (dispatch, state) => {
    dispatch({ type: OPTIONALCOVERAGE_LOADING });
    executeGet("optionalCoverage", {})
      .then((res) => {
        const { data } = res || {};
        const { token, isVerify } = data || {};
        return dispatch({ type: OPTIONALCOVERAGE_SUCCESS, payload: data });
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          // logoutAct()
        }
        return dispatch({ type: OPTIONALCOVERAGE_ERROR });
      });
  };
};

export const getPlan = () => {
  return (dispatch, state) => {
    dispatch({ type: PLAN_LOADING });
    executeGet("plans", {})
      .then((res) => {
        const { data } = res || {};
        const { token, isVerify } = data || {};
        return dispatch({ type: PLAN_SUCCESS, payload: data });
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          // logoutAct()
        }
        return dispatch({ type: PLAN_ERROR });
      });
  };
};

export const saveGuestUser = (data) => {
  return (dispatch, state) => {
    // dispatch({ type: GUESTUSER_LOADING });
    executePost("guestUser", data)
      .then((res) => {
        const { data } = res || {};
        const { token, isVerify } = data || {};
        // dispatch({ type: SAVE_GUESTUSER_SUCCESS, payload: data });
      })
      .catch(() => {
        // return dispatch({ type: SAVE_GUESTUSER_ERROR });
      });
  };
};

export const getSetting = () => {
  return (dispatch, state) => {
    dispatch({ type: SETTING_LOADING });
    executeGet(`setting`)
      .then((res) => {
        const { data } = res || {};
        return dispatch({
          type: SETTING_SUCCESS,
          payload: (data && data.length && data[0]) || {},
        });
      })
      .catch((e) => {
        return dispatch({ type: SETTING_ERROR });
      });
  };
};

export const getAffiliate = (url) => {
  return (dispatch, state) => {
    dispatch({ type: FETCH_AFFILIATE_LOADING });
    return executeGet(`affiliates/${url}`)
      .then((res) => {
        const { data } = res || {};
        dispatch({
          type: FETCH_AFFILIATE_SUCCESS,
          payload: data,
        });
        return data;
      })
      .catch((e) => {
        dispatch({ type: FETCH_AFFILIATE_ERROR });
        return e;
      });
  };
};

export const getPropertyType = () => {
  return (dispatch, state) => {
    dispatch({ type: PROPERTY_TYPE_LOADING });
    executeGet("propertyType")
      .then((res) => {
        const { data } = res || {};
        return dispatch({
          type: PROPERTY_TYPE_SUCCESS,
          payload: (data && data.length && data) || [],
        });
      })
      .catch((e) => {
        return dispatch({ type: PROPERTY_TYPE_ERROR });
      });
  };
};

export const saveHomeWarranty = (payload) => {
  return (dispatch, state) => {
    dispatch({ type: SAVE_HOME_WARRANTY_LOADING });
    return executePost("homeWarranty", payload)
      .then((res) => {
        const { data } = res || {};
        dispatch({
          type: SAVE_HOME_WARRANTY_SUCCESS,
          payload: data,
        });
        // toast.success("Home warranty submitted successfully.");
        return res;
      })
      .catch((e) => {
        const { isAxiosError, response } = e || {};
        const { data } = response || {};
        const { message } = data || {};
        dispatch({ type: SAVE_HOME_WARRANTY_ERROR });
        // toast.error(message || "Something went wrong.");
        return { isAxiosError, message };
      });
  };
};

export const getPromoCodeData = (code) => {
  if (!code) {
    return ({ type: FETCH_PROMO_CODE_SUCCESS });
  }
  return (dispatch, state) => {
    dispatch({ type: FETCH_PROMO_CODE_LOADING });
    executeGet(`promoCodes/${code}`)
      .then((res) => {
        const { data } = res || {};
        return dispatch({
          type: FETCH_PROMO_CODE_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        return dispatch({ type: FETCH_PROMO_CODE_ERROR });
      });
  };
};

export const logoutAct = () => {
  localStorage.clear();
  window.location.assign("/");
};
