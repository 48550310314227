import { useSelector, useDispatch } from 'react-redux';
import { setUserData } from '../Actions/authActions';

const useUser = () => {
    const dispatch = useDispatch();
    const loginPay = useSelector(st => st.auth.loginPay);
    return (type) => {
        if (type === 'get') {
            return dispatch(setUserData());
        } else if (type) {
            return (loginPay || {})[type];
        } else {
            return loginPay;
        }
    };
};

export default useUser;