import React from "react";
import { Modal } from "react-bootstrap";
import { useAffiliate } from '../../helpers';
import "./style.css";

const LogoutPopup = ({ open, onClose, handleOk }) => {
  const aff = useAffiliate();

  return (
      <div id="modal font-br">
        <Modal
          show={open}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="dialog"
          className="modal-backdrop-2"
        >
          {/* <Modal.Header className="m-header" closeButton></Modal.Header> */}
          <Modal.Body>
            <div>
              <h2 className="text-center">Logged out successfully</h2>
            </div>
            <div>
              <button 
                style={{ backgroundColor: aff('themecolor1') }}
                className="modal-foot mt-5 ThemeColor1"
                onClick={handleOk}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
  );
};

export default LogoutPopup;
