import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import FormError from "../../Components/FormError";
import ForgotPassword from '../../Components/ForgotPassword';
import { loginAct } from '../../Actions/authActions';
import { useAffiliate } from '../../helpers';
import './style.css';

const LoginPopup = ({ show, onHide }) => {
  const aff = useAffiliate();
  const isLoginLoad = useSelector(st => st.auth.isLoginLoad);
  const dispatch = useDispatch();

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const [fieldsErr, setFieldsErr] = useState({});
  const handleFieldsErr = (value, name) => {
    const obj = { ...fieldsErr };
    obj[name] = value;
    setFieldsErr(obj);
  };

  const [fields, setFields] = useState({});
  const handleFields = (value, name) => {
    const obj = { ...fields };
    obj[name] = value;
    setFields(obj);
  };

  const isReadyForLogin = () => {
    const { email, password } = fields || {};
    if (!email) {
      handleFieldsErr("Please enter email.", 'email');
    } else if (!password) {
      handleFieldsErr("Please enter password.", 'password');
    } else if (!isValidEmail(email)) {
      handleFieldsErr("Email isn't valid.", 'email');
    } else {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (fields.email && fieldsErr.email === "Please enter email.") {
      handleFieldsErr('', 'email');
    } else if (fields.password && fieldsErr.password === "Please enter password.") {
      handleFieldsErr('', 'password');
    } else if (isValidEmail(fields.email) && fieldsErr.email === "Email isn't valid.") {
      handleFieldsErr('', 'email');
    }
  }, [fields]);

  const [errMsg, setErrMsg] = useState('');
  const handleLogin = () => {
    if (isReadyForLogin()) {
      dispatch(loginAct(fields)).then(res => {
        const { success, message } = res || {};
        if (!success && message) {
          setErrMsg(message);
        }
      });
    }
  };

  const [isForgotPopup, setForgotPopup] = useState(false);
  const handleForgotPopup = () => setForgotPopup(!isForgotPopup);

  return (
    <div id="modal font-br">
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="dialog"
        className="modal-backdrop-2"
      >
        <Modal.Header className="m-header" closeButton></Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin(e);
            }}
            noValidate=""
          >
            <div>
              <h2>Log in</h2>
            </div>
            <div>
              <input
                type="text"
                placeholder="Username/Email Address"
                className="user"
                value={fields.email || ''}
                onChange={e => handleFields(e.target.value, 'email')}
              />
              {fieldsErr.email && (
                <p className="field-error-text">{fieldsErr.email}</p>
              )}
            </div>
            <div className="mb-3">
              <input
                type="password"
                placeholder="Password"
                className="user"
                value={fields.password || ''}
                onChange={e => handleFields(e.target.value, 'password')}
              />
              {fieldsErr.password && (
                <p className="field-error-text">{fieldsErr.password}</p>
              )}
            </div>
            <div>
              <span 
                style={{ color: aff('themecolor1') }} 
                className="account ml-1 forgot clickable"
                onClick={handleForgotPopup}
              >
                Forgot password?
              </span>
            </div>
            <div className="mt-4">
              <FormError isShow={!!errMsg} msg={errMsg} />
              <button
                type="submit"
                style={{ backgroundColor: aff('themecolor1') }}
                className="modal-foot mt-2 ThemeColor1"
                onClick={handleLogin}
                disabled={isLoginLoad}
              >
                {isLoginLoad ? <Spinner animation="border" size="sm" /> : 'LOG IN'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ForgotPassword show={isForgotPopup} onHide={handleForgotPopup} />
    </div>
  );
}
 
export default LoginPopup;