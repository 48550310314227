import {
    SET_FORM_DATA,
    SET_FORM_STEP,
} from "../../constants";
  
const initState = {
    formData: {
        ...JSON.parse(localStorage.getItem("guestUser")),
        paymentInfo: { paidBy: "cc" },
    },
    step: 2,
};

const formReducer = (state = initState, action) => {
    const { type, payload } = action || {};
    const setInitState = (obj = {}) => {
        return { ...state, ...obj };
    };

    switch (type) {
        case SET_FORM_DATA:
            return setInitState({
                formData: payload,
            });
        case SET_FORM_STEP:
            return setInitState({
                step: payload,
            });
        default:
            return state;
    }
};

export default formReducer;
  