import React, { Fragment } from 'react';
import classnames from 'classnames';
import './style.css';

const FormError = ({ isShow, msg, className }) => {

    if (!isShow) {
        return <Fragment />;
    }

    return (
        <div className={classnames("form-error-bar", { [className]: className })}>
            {msg || 'Something went wrong.'}
        </div>
    );
}
 
export default FormError;