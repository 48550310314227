import React, { forwardRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import { Dropdown } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import { useUser, useAffiliate, useRedirect } from '../../helpers';
import "./header.css";
import "../../Pages/Home/registration.css";
import LazyLoadImages from "../LazyLoad/Image";
import LoginPopup from '../LoginPopup';
import LogoutPopup from '../LogoutPopup';

const Header = () => {
  const user = useUser();
  const aff = useAffiliate();
  const redirectTo = useRedirect();
  const affiliateData = useSelector(st => st.auth.affiliateData);
  const { homePagePromoTextBanner } = affiliateData || {};
  const themeColor1 = "#cf1f24";
  const themeColor2 = "#2d2d2d";
  let globalCustomCSSWithTheme = `.ThemeColor1 {background-color: ${themeColor1}}`;
  globalCustomCSSWithTheme = `${globalCustomCSSWithTheme} .ThemeColor2 {background-color: ${themeColor2}}`;

  const [show, setShow] = useState(false);
  const [style, setStyle] = useState("menu");
  const [menuStatus, setMenuStatus] = useState("open");

  const handleShow = () => {
    setShow(!show);
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => {
    return (
      <li
        className="navfirst clickable"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </li>
    );
  });

  const CustomItem = forwardRef(
    ({ children, onClick, className, href }, ref) => {
      return (
        <a
          ref={ref}
          onClick={(e) => {
            onClick(e);
          }}
          href={href}
          target="_blank"
          rel="noreferrer"
          className={className}
        >
          {children}
        </a>
      );
    }
  );

  const handleClick = (menuStatus) => {
    switch (menuStatus) {
      case "open":
        setStyle("menu active");
        setMenuStatus("close");
        break;
      case "close":
        setStyle("menu");
        setMenuStatus("open");
        break;
    }
  };

  const [isLogoutPopup, setLogoutPopup] = useState(false);
  const handleLogoutPopup = () => setLogoutPopup(prev => !prev);

  const handleLogout = () => {
    localStorage.removeItem('token');
    handleLogoutPopup();
  };

  return (
    <>
      <div className="housecheck-header-container">
        <Helmet
          style={[
            {
              cssText: `${globalCustomCSSWithTheme}`,
            },
          ]}
        />
        <span className={`side-bar-${menuStatus}`}>
          <Sidebar
            menuStatus={menuStatus}
            style={style}
            handleClick={handleClick}
          />
        </span>
        <div className="housecheck-header-bar-1">
          <a href="tel:8664297689">
            <p>Call for quote 866-429-7689</p>
          </a>
          <div className="header-right-btns-container">
            <button className="header-right-btns header-right-btn-1">
              <Link 
                to="/service-request" 
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  redirectTo('service-request');
                }}
              >
                SERVICE REQUEST
              </Link>
            </button>
            {/* <button
              className="header-right-btns header-right-btn-2"
              data-toggle="modal"
              data-target="#myModal"
              onClick={() => handleShow()}
            >
              <img src="/image/log-in.svg" alt="" />
              <span className="ml-2">LOG IN</span>
            </button> */}
            {/* <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <button className="header-right-btns header-right-btn-2">
                <LazyLoadImages src="/image/log-in.svg" alt="" />
                <span>LOG IN</span>
              </button>
            </Dropdown> */}
            {user('id') ? (
              <div className="dropdown">
                <button
                  className="header-right-btns header-right-btn-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout(e);
                  }}
                >
                  <LazyLoadImages src="/image/log-in.svg" alt="" />
                  LOG OUT
                </button>
              </div>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  as={forwardRef(({ children, onClick }, ref) => {
                    return (
                      <button
                        className="header-right-btns header-right-btn-2"
                        ref={ref}
                        onClick={(e) => {
                          e.preventDefault();
                          onClick(e);
                        }}
                      >
                        {children}
                      </button>
                    );
                  })}
                >
                  <LazyLoadImages src="/image/log-in.svg" alt="" />
                  <span>LOG IN</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={CustomItem}
                    href="https://folio.homeonewarranty.com/customerportal/signin/unauthenticated"
                    className="login-dropdown-option"
                  >
                    Customer Log In
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={CustomItem}
                    onClick={() => handleShow()}
                    className="login-dropdown-option"
                  >
                    Partner Log In
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="housecheck-header-bar-2">
          <span onClick={() => handleClick("open")}>
            <img src="/image/menu.svg" className="header-menu" alt="" />
          </span>
          <div className="navlogo-container clickable" onClick={() => redirectTo('')}>
            <LazyLoadImages src={aff('logo') || "/image/header-logo.png"} className="navlogo" alt="" />
          </div>
          <img src="/image/search.svg" className="header-search" alt="" />

          <ul className="nav">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>Services</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/home-inspection"
                >
                  Home Inspection
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/">
                  Home Warranty
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/environmental"
                >
                  Environmental Services
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="dropdown">
              <li
                className="navfirst clickable"
                onClick={(e) => redirectTo('locations')}
              >
                Locations
              </li>
            </div>
            {/* <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>Locations</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="location-sub-menu-container">
                  Florida
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/panama-city-fl",
                          "_blank"
                        )
                      }
                    >
                      Panama
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/pensacola-fl",
                          "_blank"
                        )
                      }
                    >
                      Pensacola
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/southwest-fl",
                          "_blank"
                        )
                      }
                    >
                      Southwest
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/tallahassee-fl",
                          "_blank"
                        )
                      }
                    >
                      Tallahassee
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/tampa-fl",
                          "_blank"
                        )
                      }
                    >
                      Tampa
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Idaho
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/boise-id",
                          "_blank"
                        )
                      }
                    >
                      Boise
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Indiana
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/indianapolis-in",
                          "_blank"
                        )
                      }
                    >
                      Indianapolis
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/south-bend-in",
                          "_blank"
                        )
                      }
                    >
                      South Bend
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  New Jersey
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/north-nj",
                          "_blank"
                        )
                      }
                    >
                      North
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/central-nj",
                          "_blank"
                        )
                      }
                    >
                      Central
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Pennsylvania
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/philadelphia-pa",
                          "_blank"
                        )
                      }
                    >
                      Philadelphia
                    </li>
                  </ul>
                </Dropdown.Item>
                <Dropdown.Item className="location-sub-menu-container">
                  Texas
                  <ul className="location-sub-menu">
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/austin-tx",
                          "_blank"
                        )
                      }
                    >
                      Austin
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/dallas-tx",
                          "_blank"
                        )
                      }
                    >
                      Dallas
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/fort-worth-tx",
                          "_blank"
                        )
                      }
                    >
                      Fort Worth
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/houston-tx",
                          "_blank"
                        )
                      }
                    >
                      Houston
                    </li>
                    <li
                      className="location-sub-menu-li"
                      onClick={(e) =>
                        window.open(
                          "https://housecheck.com/local/san-antonio-tx",
                          "_blank"
                        )
                      }
                    >
                      San Antonio
                    </li>
                  </ul>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>About Us</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/about"
                >
                  Our Company
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/reviews"
                >
                  Reviews
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/faqs"
                >
                  FAQ's
                </Dropdown.Item>
                <Dropdown.Item
                  as={CustomItem}
                  href="https://housecheck.com/team"
                >
                  Our Team
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
        {homePagePromoTextBanner && homePagePromoTextBanner.length > 0 && (
          <div className="housecheck-header-bar-3">
            {homePagePromoTextBanner[0] || ''}
            <span className="vertical-hr" />
            <span>{homePagePromoTextBanner[1] || ''}</span>
          </div>
        )}
      </div>
      <LoginPopup
        show={show}
        onHide={handleShow}
      />
      <LogoutPopup
        open={isLogoutPopup}
        onClose={handleLogoutPopup}
        handleOk={() => window.location.reload()}
      />
    </>
  );
};

export default Header;
