/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row, Col } from 'react-bootstrap';
import { useAffiliate, useRedirect } from '../../helpers';
import LazyLoadImages from "../LazyLoad/Image";
import "./footer.css";

const Footer = () => {
  const aff = useAffiliate();
  const redirectTo = useRedirect();

  return (
    <>
      <div className="container footer-contain">
        <div className="housecheck-footer-container">
          <Row className="full-width">
            <Col xs={12} sm={12} md={12} lg={6}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="footer-part1">
                    <div className="foot-in">
                      <div className="footer-logo-container clickable" onClick={() => redirectTo('')}>
                        <LazyLoadImages src={aff('logo') || "/image/group18.svg"} className="footer-logo" alt="" />
                      </div>
                    </div>
                    <div className="icon-group">
                      <a href="https://www.facebook.com/nationalhousecheck/">
                        <i className="fa fa-facebook icon1 clickable" />
                      </a>
                      <a href="https://twitter.com/HouseCheckcom">
                        <i className="fa fa-twitter icon1 clickable" />
                      </a>
                      <a href="https://www.instagram.com/nationalhousecheck/">
                        <i className="fa fa-instagram icon1 clickable" />
                      </a>
                      <a href="https://www.linkedin.com/company/national-housecheck/">
                        <i className="fa fa-linkedin icon1 clickable" />
                      </a>
                      <a href="https://www.youtube.com/channel/UCprH9wdGuQFAeo_P_dETaOA">
                        <i className="fa fa-youtube-play icon1 clickable" />
                      </a>
                      <a href="https://www.yelp.com/biz/housecheck-boise-5">
                        <img
                          src="/image/yelp.svg"
                          className="fa fa-edge icon1 clickable"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="footer-part2 font-br">
                    <h6 className="HouseCheck">Home Inspections</h6>
                    <div className="txt"><a href="tel:8449424325">(844) 94-CHECK</a></div>
                    <div className="txt"><a href="tel:8449424325">(844) 942-4325</a></div>

                    <h6 className="HouseCheck mt-3 mb-2">Home Warranty</h6>
                    <div className="txt"><a href="tel:8664297689">(866) 42-WRNTY</a></div>
                    <div className="txt"><a href="tel:8664297689">(866) 429-7689</a></div>

                    <h6 className="HouseCheck mt-3 mb-2">Environmental Services</h6>
                    <div className="txt"><a href="tel:8333687827">(833) ENV-SVCS</a></div>
                    <div className="txt"><a href="tel:8333687827">(833) 368-7827</a></div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="footer-part3 font-br">
                    <ul className="part3-list">
                      <li className="home-li clickable txt">
                        <a className="footer-opt" href="https://housecheck.com/home-inspection">
                          Home Inspection
                        </a>
                      </li>
                      <li className="clickable txt">
                        <a className="footer-opt" href="#">
                          Home Warranty
                        </a>
                      </li>
                      <li className="clickable txt">
                        <a className="footer-opt" href="https://housecheck.com/environmental">
                          Environmental Services
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="footer-part4 font-br">
                    <ul className="part3-list">
                      <li className="home-li clickable txt">
                        <a className="footer-opt" href="/">Home</a>
                      </li>
                      <li className="home-li clickable txt">
                        <a className="footer-opt" href="https://housecheck.com/category/press">Press/blog</a>
                      </li>
                      <li className="home-li clickable txt">
                        <a className="footer-opt" href="/contact">Contact Us</a>
                      </li>
                      <li className="home-li clickable txt">
                        <a className="footer-opt" href="https://housecheck.com/terms/">Terms of Use</a>
                      </li>
                      <li className="home-li clickable txt">
                        <a className="footer-opt" href="https://housecheck.com/privacy-policy/">
                          Privacy
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer-line font-br ThemeColor2">
          © 2021 National HouseCheck Corporation. All rights reserved.{" "}
          {/* <a id="privacy-link" href="https://housecheck.com/privacy-policy/">
            Privacy Policy
          </a> */}
          {" "}
          {" "}
          {/* <a id="terms-link" href="https://housecheck.com/terms/">
            Terms &amp; Conditions
          </a> */}
          {" "}
        </div>
      </div>
    </>
  );
};

export default Footer;
