import Loadable from 'react-loadable';
import React from 'react';

function Loading(props) {
  if (props.error) {
    console.log(props.error);
    // window.location.reload();
    return <div>Please reload this page <button onClick={()=> window.location.reload()}>Retry</button></div>;
  } else if (props.timedOut) {
    window.location.reload();
    return <div>It appears that your network is slow... please reload this page. <button onClick={()=>window.location.reload()}>Retry</button></div>;
  } else if (props.pastDelay) {
    return <></>;
  } else {
    return null;
  }
}

const Home = Loadable({
  loader: () => import('./Pages/Home'),
  loading: Loading
});

const ServiceRequest = Loadable({
  loader: () => import('./Pages/ServiceRequest'),
  loading: Loading
});

const Locations = Loadable({
  loader: () => import('./Pages/Locations'),
  loading: Loading
});

const ResetPassword = Loadable({
  loader: () => import('./Pages/ResetPassword'),
  loading: Loading
});

const withAffiliate = path => `/:affiliate/${path}`;

const routes = [
  {
    exact: true,
    path: withAffiliate("reset-password/:tokenHash"),
    component: ResetPassword,
    isProtected: false,
    isAuthPage: true,
    isProtectedLayout: true,
  },
  {
    exact: true,
    path: withAffiliate("service-request"),
    component: ServiceRequest,
    isProtected: false,
    isAuthPage: true,
    isProtectedLayout: true,
  },
  {
    exact: true,
    path: withAffiliate("locations"),
    component: Locations,
    isProtected: false,
    isAuthPage: true,
    isProtectedLayout: true,
  },
  {
    exact: true,
    path: withAffiliate(":form"),
    component: Home,
    isProtected: false,
    isAuthPage: true,
    isProtectedLayout: true,
  },
];

export default routes;
