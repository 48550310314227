import axios from 'axios';
const baseURL = process.env.REACT_APP_API;

const axiosApi = axios.create({
  baseURL: baseURL,
});

export const setAuthHeader = token => {
  axiosApi.defaults.headers.common.Authorization =
    `bearer ${token || localStorage.getItem('token')}`;
};

axiosApi.defaults.headers.common.Authorization = `bearer ${localStorage.getItem(
  'token',
)}`;

//if a 401 happens, the user will be logged out
axiosApi.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error && error.response && error.response.status === 401) {
    localStorage.removeItem('token');
  }
  return Promise.reject(error);
});

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(localStorage.getItem('token'));

export default axiosApi;
