import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  OPTIONALCOVERAGE_LOADING,
  OPTIONALCOVERAGE_SUCCESS,
  OPTIONALCOVERAGE_ERROR,
  PLAN_LOADING,
  PLAN_SUCCESS,
  PLAN_ERROR,
  FILE_UPLOAD_LOADING,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERROR,
  TASK_SAVE_LOADING,
  TASK_SAVE_SUCCESS,
  TASK_SAVE_ERROR,
  TASK_DATA_LOADING,
  TASK_DATA_SUCCESS,
  TASK_DATA_ERROR,
  QUOTE_LOADING,
  QUOTE_SUCCESS,
  QUOTE_ERROR,
  QUOTE_DATA_LOADING,
  QUOTE_DATA_SUCCESS,
  QUOTE_DATA_ERROR,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SETTING_LOADING,
  SETTING_SUCCESS,
  SETTING_ERROR,
  PROPERTY_TYPE_LOADING,
  PROPERTY_TYPE_SUCCESS,
  PROPERTY_TYPE_ERROR,
  SAVE_HOME_WARRANTY_LOADING,
  SAVE_HOME_WARRANTY_SUCCESS,
  SAVE_HOME_WARRANTY_ERROR,
  FETCH_PROMO_CODE_LOADING,
  FETCH_PROMO_CODE_SUCCESS,
  FETCH_PROMO_CODE_ERROR,
  FETCH_AFFILIATE_LOADING,
  FETCH_AFFILIATE_SUCCESS,
  FETCH_AFFILIATE_ERROR,
} from "../../constants";

const initState = {
  isLoginLoad: false,
  isLoginError: false,
  isCalendarLoad: false,
  isCalendarError: false,
  loginPay: null,
  calendar: {},
  quoteData: {},
  isRegisterLoad: false,
  isRegisterError: false,
  registerPay: null,
  isSettingLoad: false,
  isSettingError: false,
  isPropertyTypeLoad: false,
  isPropertyTypeError: false,
  isSaveHomeWarrantyLoad: false,
  isSaveHomeWarrantyError: false,
  isPromoCodeLoad: false,
  isPromoCodeError: false,
  isAffiliateLoad: false,
  isAffiliateError: false,
  taskList: {},
  quote: [],
  optionalCoverage: [],
  plans: [],
  setting: {},
  propertyType: [],
  savedHomeWarranty: null,
  promoCodeData: null,
  affiliateData: null
};

const authReducer = (state = initState, action) => {
  const { type, payload } = action || {};
  const setInitState = (obj = {}) => {
    return { ...state, ...obj };
  };

  switch (type) {
    case LOGIN_LOADING:
      return setInitState({
        isLoginLoad: true,
        isLoginError: false,
      });
    case LOGIN_SUCCESS:
      return setInitState({
        loginPay: payload,
        isLoginLoad: false,
      });
    case LOGIN_ERROR:
      return setInitState({
        isLoginError: true,
        isLoginLoad: false,
      });
    case OPTIONALCOVERAGE_LOADING:
      return setInitState({
        isOptionalCoverageLoad: true,
        isOptionalCoveragerror: false,
      });
    case OPTIONALCOVERAGE_SUCCESS:
      return setInitState({
        optionalCoverage: payload,
        isOptionalCoverageLoad: false,
      });
    case OPTIONALCOVERAGE_ERROR:
      return setInitState({
        isOptionalCoverageError: true,
        isOptionalCoverageLoad: false,
      });
    case PLAN_LOADING:
      return setInitState({
        isPlanLoad: true,
        isPlanError: false,
      });
    case PLAN_SUCCESS:
      return setInitState({
        plans: payload,
        isPlanLoad: false,
      });
    case PLAN_ERROR:
      return setInitState({
        isPlanError: true,
        isPlanLoad: false,
      });
    case FILE_UPLOAD_LOADING:
      return setInitState({
        isFileUploadLoad: true,
        isFileUploadError: false,
      });
    case FILE_UPLOAD_SUCCESS:
      return setInitState({
        fileUploadPay: payload,
        isFileUploadLoad: false,
      });
    case FILE_UPLOAD_ERROR:
      return setInitState({
        isFileUploadError: true,
        isFileUploadLoad: false,
      });
    case TASK_SAVE_LOADING:
      return setInitState({
        isTaskSaveLoad: true,
        isTaskSaveError: false,
      });
    case TASK_SAVE_SUCCESS:
      return setInitState({
        taskSavePay: payload,
        isTaskSaveLoad: false,
      });
    case TASK_SAVE_ERROR:
      return setInitState({
        isTaskSaveError: true,
        isTaskSaveLoad: false,
      });
    case TASK_DATA_LOADING:
      return setInitState({
        isTaskListLoad: true,
        isTaskDataError: false,
      });
    case TASK_DATA_SUCCESS:
      return setInitState({
        taskList: payload,
        isTaskListLoad: false,
      });
    case TASK_DATA_ERROR:
      return setInitState({
        isTaskListError: true,
        isTaskListLoad: false,
      });
    case QUOTE_LOADING:
      return setInitState({
        isCalendarLoad: true,
        isCalendarError: false,
      });
    case QUOTE_SUCCESS:
      return setInitState({
        calendar: payload,
        isCalendarLoad: false,
      });
    case QUOTE_ERROR:
      return setInitState({
        isCalendarError: true,
        isCalendarLoad: false,
      });
    case QUOTE_DATA_LOADING:
      return setInitState({
        isquoteDataLoad: true,
        isquoteDataError: false,
      });
    case QUOTE_DATA_SUCCESS:
      return setInitState({
        quoteData: payload,
        isquoteDataLoad: false,
      });
    case QUOTE_DATA_ERROR:
      return setInitState({
        isquoteDataError: true,
        isquoteDataLoad: false,
      });
    case REGISTER_LOADING:
      return setInitState({
        isRegisterLoad: true,
        isRegisterError: false,
      });
    case REGISTER_SUCCESS:
      return setInitState({
        registerPay: payload,
        isRegisterLoad: false,
      });
    case REGISTER_ERROR:
      return setInitState({
        isRegisterError: true,
        isRegisterLoad: false,
      });
    case SETTING_LOADING:
      return setInitState({
        isSettingLoad: true,
        isSettingError: false,
      });
    case SETTING_SUCCESS:
      return setInitState({
        setting: payload,
        isSettingLoad: false,
      });
    case SETTING_ERROR:
      return setInitState({
        isSettingError: true,
        isSettingLoad: false,
      });
    case PROPERTY_TYPE_LOADING:
      return setInitState({
        isPropertyTypeLoad: true,
        isPropertyTypeError: false,
      });
    case PROPERTY_TYPE_SUCCESS:
      return setInitState({
        propertyType: payload,
        isPropertyTypeLoad: false,
      });
    case PROPERTY_TYPE_ERROR:
      return setInitState({
        isPropertyTypeError: true,
        isPropertyTypeLoad: false,
      });
    case SAVE_HOME_WARRANTY_LOADING:
      return setInitState({
        isSaveHomeWarrantyLoad: true,
        isSaveHomeWarrantyError: false,
      });
    case SAVE_HOME_WARRANTY_SUCCESS:
      return setInitState({
        savedHomeWarranty: payload,
        isSaveHomeWarrantyLoad: false,
      });
    case SAVE_HOME_WARRANTY_ERROR:
      return setInitState({
        isSaveHomeWarrantyError: true,
        isSaveHomeWarrantyLoad: false,
      });
    case FETCH_PROMO_CODE_LOADING:
      return setInitState({
        isPromoCodeLoad: true,
        isPromoCodeError: false,
      });
    case FETCH_PROMO_CODE_SUCCESS:
      return setInitState({
        promoCodeData: payload,
        isPromoCodeLoad: false,
      });
    case FETCH_PROMO_CODE_ERROR:
      return setInitState({
        isPromoCodeError: true,
        isPromoCodeLoad: false,
      });
    case FETCH_AFFILIATE_LOADING:
      return setInitState({
        isAffiliateLoad: true,
        isAffiliateError: false,
      });
    case FETCH_AFFILIATE_SUCCESS:
      return setInitState({
        affiliateData: payload,
        isAffiliateLoad: false,
      });
    case FETCH_AFFILIATE_ERROR:
      return setInitState({
        isAffiliateError: true,
        isAffiliateLoad: false,
      });
    default:
      return state;
  }
};

export default authReducer;
