import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./protectedLayout.css";

const ProtectedLayout = (props) => {
  const { children: Component, ...rest } = props || {};
  return (
    <React.Fragment>
      <div className="main-container">
        <Header {...rest} />
        <div className="component-container">
          <Component {...rest} />
        </div>
        <Footer {...rest} />
      </div>
    </React.Fragment>
  );
};

export default ProtectedLayout;
